import React, { useEffect, useState } from "react";
import { useFiles } from "../files/filesStore";
import { Files } from "../files/Files";
import { Toolbar } from "../tools/Toolbar";
import PreviewScreen from "../preview/Preview";
import { useRoute } from "wouter";
import { getShare } from "../api/api";

interface ShareData {
    name: string,
    description: string,
    tags: string[],
    events: string[],
    exclude_tags: string[],
}

export default function SharedApp() {
    const [shareData, setShareData] = useState<ShareData>();

    const { id } = useRoute("/share/:id")[1]!

    useEffect(() => {
        (async function () {
            const data = await getShare(id);
            useFiles.getState().hydrateFiles(data.files);
            setShareData(data.share);
        })();
    }, []);

    if (!shareData)
        return <>Loading...</>

    return <>
        <h1>{shareData.name}</h1>
        <div>
            <p>{shareData.description}</p>
        </div>
        <div className="main-content">
            <Files />
        </div>

        <PreviewScreen />
    </>
}