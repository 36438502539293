import { deleteFiles } from "../files/filesStore";
import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelection } from "./selectionStore";
import { IconButton } from "../utils/IconButton";

async function deleteSelection() {
    const { selection, setSelection } = useSelection.getState();
    setSelection([]);
    await deleteFiles(selection);
}
export function DeleteSelectionButton() {
    return <IconButton
        variant="outline-danger"
        onClick={() => deleteSelection()}
        icon={faTrash}
        text="Delete"
    />;
}
