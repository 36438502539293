import { processAndUpload } from "./processAndUpload";

let database: Promise<IDBDatabase> = new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open("fotodingDb", 1);
    dbRequest.onsuccess = function (e) {
        resolve(this.result);
    };
    dbRequest.onerror = (e) => {
        reject((e.target as any).error);
    }
    dbRequest.onupgradeneeded = function (e) {
        const db = this.result;
        const store = db.createObjectStore("UploadQueue", { autoIncrement: true });
    }
})


function promisify<T>(request: IDBRequest<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        request.onsuccess = function (e) {
            resolve(this.result);
        }
        request.onerror = function (e) {
            reject((e.target as any).error);
        }
    });
}

function promisifyTransaction(transaction: IDBTransaction) {
    return new Promise<void>((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = function (e) {
            reject((e.target as any).error);
        }
    });
}

interface DbFile {
    path: string,
    event?: string,
}

interface DbEntry<T> {
    key: IDBValidKey
    value: T
}
export async function getFiles() {
    const db = await database;
    const t = db.transaction("UploadQueue", "readonly");
    const q = t.objectStore("UploadQueue");

    // return new Promise<DbEntry<DbFile>[]>((resolve, reject) => {
    //     const c = q.openCursor();
    //     const results: DbEntry<DbFile>[] = [];
    //     c.onsuccess = function () {
    //         if (!this.result) {
    //             resolve(results);
    //             return;
    //         }
    //         const { key, value } = this.result;
    //         results.push({ key, value });
    //     }
    //     c.onerror = () => reject(c.error);
    // });

    const request = q.getAll();
    return promisify<DbFile[]>(request);
}

export async function putFiles(files: DbFile[]) {
    const db = await database;
    const t = db.transaction("UploadQueue", "readwrite");
    for (const file of files) {
        t.objectStore("UploadQueue").put(file, file.path)
    }
    t.commit();
    return promisifyTransaction(t);
}

export async function removeFiles(filePaths: string[]) {
    const db = await database;
    const t = db.transaction("UploadQueue", "readwrite");
    for (const path of filePaths) {
        t.objectStore("UploadQueue").delete(path)
    }
    t.commit();
    return promisifyTransaction(t);
}
