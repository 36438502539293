import React from "react";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../utils/IconButton";
import { useAuth } from "./authStore";
import { navigate } from "wouter/use-browser-location"

export function LogoutButton() {
    return <IconButton
        icon={faArrowRightFromBracket}
        text="Log out"
        variant="outline-secondary"
        onClick={() => {
            useAuth.getState().logOut();
            navigate("/login");
        }}
    />;
}
