import React, { useState } from "react";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from "react-bootstrap";
import { IconButton } from "../utils/IconButton";
import { useSelection } from "./selectionStore";
import { modifyFiles, useFiles } from "../files/filesStore";
import { ThumbnailImage } from "../utils/ThumbnailImage";

export function ChangeDateButton() {
    const [show, setShow] = useState(false);
    return <>
        <IconButton
            icon={faCalendar}
            text="Change date"
            variant="outline-secondary"
            onClick={() => setShow(true)}
        />
        <Modal show={show} onHide={() => setShow(false)}>
            <DateChangeModalContent onHide={() => setShow(false)} />
        </Modal>
    </>
}

function DateChangeModalContent(props: { onHide: () => void }) {
    const selected = useSelection(s => s.selection);
    const selectedFiles = useFiles(f => selected.map(s => f.files[s]));
    const initialDate = selectedFiles[0].meta.date.split("T")[0];
    const [date, setDate] = useState(initialDate)
    const dt = Date.parse(date) - Date.parse(initialDate);

    const getNewDate = (date: string) => new Date(Date.parse(date) + dt).toISOString();

    const confirm = () => {
        modifyFiles(selectedFiles.map(({ name, meta: { date } }) => ({ name, date: getNewDate(date) })));
        props.onHide();
    }

    return <>
        <ModalHeader closeButton>
            <ModalTitle>Change date</ModalTitle>
        </ModalHeader>
        <ModalBody>
            Change date to: <input type="date" value={date} onChange={e => setDate(e.target.value)} />
            <br />
            <hr />
            <h4>New dates</h4>
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "start", columnGap: "5px" }}>
                {selectedFiles.map(f => <>
                    <div>
                        <ThumbnailImage file={f.name} /><br />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", placeContent: "start" }}>
                        <h5 style={{ gridColumn: "span 2" }}>{f.meta.originalName}</h5>
                        <span></span>
                        <span>{f.meta.date}</span>
                        <span>⇒&nbsp;</span>
                        <span>{getNewDate(f.meta.date)}</span>
                    </div>
                </>)}
            </div>
        </ModalBody>
        <ModalFooter>
            <Button variant="outline-secondary" onClick={() => props.onHide()}>Cancel</Button>
            <Button variant="primary" onClick={confirm}>OK</Button>
        </ModalFooter>
    </>
}
