/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import useSwr from 'swr'
import type {
  SWRConfiguration,
  Key
} from 'swr'
import { fetchWithAuth } from './fetchWithAuth';
import type { ErrorType, BodyType } from './fetchWithAuth';
export type PutFileParams = {
event?: string | null;
};

export type GetFiles200 = {[key: string]: FileSlim};

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type ShareDataPartialDescription = string | null | null;

export type ShareDataPartialName = string | null | null;

export interface ShareDataPartial {
  name?: ShareDataPartialName;
  description?: ShareDataPartialDescription;
  tags?: string[];
  events?: string[];
  exclude_tags?: string[];
}

export interface ShareData {
  name: string;
  description: string;
  tags?: string[];
  events?: string[];
  exclude_tags?: string[];
}

/**
 * MongoDB document ObjectID
 */
export type _ShareId = string | null;

export interface Share {
  name: string;
  description: string;
  tags?: string[];
  events?: string[];
  exclude_tags?: string[];
  /** MongoDB document ObjectID */
  _id?: _ShareId;
}

export interface SessionInfo {
  id: string;
  last_used: string;
  description: string;
}

export type ModifyInputDate = string | null;

export type ModifyInputEvent = string | null;

export interface ModifyInput {
  name: string;
  event?: ModifyInputEvent;
  tags?: string[];
  date?: ModifyInputDate;
}

export interface MetadataSlim {
  size: number;
  width: number;
  height: number;
  previewSize: number;
  previewWidth: number;
  previewHeight: number;
  thumbnailSize: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  originalName: string;
  date: string;
  previewIsVideo?: boolean;
}

export type MetadataExtracted = {[key: string]: number | number | string | boolean | string | null};

export interface Metadata {
  size: number;
  width: number;
  height: number;
  previewSize: number;
  previewWidth: number;
  previewHeight: number;
  thumbnailSize: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  originalName: string;
  date: string;
  previewIsVideo?: boolean;
  extracted?: MetadataExtracted;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export type FilesByYearNextYear = number | null;

export type FilesByYearPrevYear = number | null;

export interface FilesByYear {
  files: FileSlim[];
  prevYear: FilesByYearPrevYear;
  nextYear: FilesByYearNextYear;
}

export type FileSlimEvent = string | null;

export interface FileSlim {
  name: string;
  blobs: FileBlobs;
  event?: FileSlimEvent;
  tags?: string[];
  meta: MetadataSlim;
}

export interface ShareInfo {
  share: Share;
  files: FileSlim[];
}

export type FileBlobsThumbnail = string | null;

export type FileBlobsPreview = string | null;

export interface FileBlobs {
  main: string;
  preview?: FileBlobsPreview;
  thumbnail?: FileBlobsThumbnail;
}

export type FileEvent = string | null;

/**
 * MongoDB document ObjectID
 */
export type _FileId = string | null;

export interface File {
  /** MongoDB document ObjectID */
  _id?: _FileId;
  name: string;
  blobs: FileBlobs;
  event?: FileEvent;
  tags?: string[];
  meta: Metadata;
}

export type BodyPutFileApiFilePathPutMeta = { [key: string]: any };

export interface BodyPutFileApiFilePathPut {
  blobs: FileBlobs;
  meta: BodyPutFileApiFilePathPutMeta;
}

export type BodyGenerateTokenApiAuthTokenPostClientSecret = string | null;

export type BodyGenerateTokenApiAuthTokenPostClientId = string | null;

export type BodyGenerateTokenApiAuthTokenPostGrantType = string | null;

export interface BodyGenerateTokenApiAuthTokenPost {
  grant_type?: BodyGenerateTokenApiAuthTokenPostGrantType;
  username: string;
  password: string;
  scope?: string;
  client_id?: BodyGenerateTokenApiAuthTokenPostClientId;
  client_secret?: BodyGenerateTokenApiAuthTokenPostClientSecret;
}

export interface BodyCreateUserApiUsersNewPost {
  username: string;
  password: string;
}

export type BlobLinksThumbnail = string | null;

export type BlobLinksPreview = string | null;

export type BlobLinksMain = string | null;

export interface BlobLinks {
  main?: BlobLinksMain;
  preview?: BlobLinksPreview;
  thumbnail?: BlobLinksThumbnail;
}




  type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Get Sessions
 */
export const getSessions = (
    
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<SessionInfo[]>(
      {url: `/api/auth/sessions/sessions/`, method: 'get'
    },
      options);
    }
  

export const getGetSessionsKey = () => [`/api/auth/sessions/sessions/`] as const;

    
export type GetSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessions>>>
export type GetSessionsQueryError = ErrorType<unknown>

/**
 * @summary Get Sessions
 */
export const useGetSessions = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getSessions>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetSessionsKey() : null);
  const swrFn = () => getSessions(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Create Session
 */
export const createSession = (
    createSessionBody: BodyType<string>,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<string>(
      {url: `/api/auth/sessions/sessions/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSessionBody
    },
      options);
    }
  


/**
 * @summary Get Session Access Token
 */
export const getSessionAccessToken = (
    getSessionAccessTokenBody: BodyType<string>,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<string>(
      {url: `/api/auth/sessions/sessions/get_token`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getSessionAccessTokenBody
    },
      options);
    }
  


/**
 * @summary Revoke Session
 */
export const revokeSession = (
    id: string,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<unknown>(
      {url: `/api/auth/sessions/sessions/${id}`, method: 'delete'
    },
      options);
    }
  


/**
 * @summary Get Current User
 */
export const getCurrentUser = (
    
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<string>(
      {url: `/api/users/me/`, method: 'get'
    },
      options);
    }
  

export const getGetCurrentUserKey = () => [`/api/users/me/`] as const;

    
export type GetCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentUser>>>
export type GetCurrentUserQueryError = ErrorType<unknown>

/**
 * @summary Get Current User
 */
export const useGetCurrentUser = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCurrentUser>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCurrentUserKey() : null);
  const swrFn = () => getCurrentUser(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Create User
 */
export const createUser = (
    bodyCreateUserApiUsersNewPost: BodyType<BodyCreateUserApiUsersNewPost>,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<unknown>(
      {url: `/api/users/new/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bodyCreateUserApiUsersNewPost
    },
      options);
    }
  


/**
 * @summary List Users
 */
export const listUsers = (
    
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<string[]>(
      {url: `/api/users/`, method: 'get'
    },
      options);
    }
  

export const getListUsersKey = () => [`/api/users/`] as const;

    
export type ListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type ListUsersQueryError = ErrorType<unknown>

/**
 * @summary List Users
 */
export const useListUsers = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof listUsers>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getListUsersKey() : null);
  const swrFn = () => listUsers(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Delete User
 */
export const deleteUser = (
    name: string,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<unknown>(
      {url: `/api/users/${name}`, method: 'delete'
    },
      options);
    }
  


/**
 * @summary Get Files
 */
export const getFiles = (
    
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<GetFiles200>(
      {url: `/api/file/`, method: 'get'
    },
      options);
    }
  

export const getGetFilesKey = () => [`/api/file/`] as const;

    
export type GetFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getFiles>>>
export type GetFilesQueryError = ErrorType<unknown>

/**
 * @summary Get Files
 */
export const useGetFiles = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getFiles>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetFilesKey() : null);
  const swrFn = () => getFiles(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Get Files By Year
 */
export const getFilesByYear = (
    year: number,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<FilesByYear>(
      {url: `/api/file/by-year/${year}/`, method: 'get'
    },
      options);
    }
  

export const getGetFilesByYearKey = (year: number,) => [`/api/file/by-year/${year}/`] as const;

    
export type GetFilesByYearQueryResult = NonNullable<Awaited<ReturnType<typeof getFilesByYear>>>
export type GetFilesByYearQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Files By Year
 */
export const useGetFilesByYear = <TError = ErrorType<HTTPValidationError>>(
 year: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getFilesByYear>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(year)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetFilesByYearKey(year) : null);
  const swrFn = () => getFilesByYear(year, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Bulk Modify
 */
export const bulkModify = (
    modifyInput: ModifyInput[],
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<ModifyInput[]>(
      {url: `/api/file/modify`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modifyInput
    },
      options);
    }
  


/**
 * @summary Bulk Delete
 */
export const bulkDelete = (
    bulkDeleteBody: string[],
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<unknown>(
      {url: `/api/file/delete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bulkDeleteBody
    },
      options);
    }
  


/**
 * @summary Put File
 */
export const putFile = (
    path: string,
    bodyPutFileApiFilePathPut: BodyType<BodyPutFileApiFilePathPut>,
    params?: PutFileParams,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<BlobLinks>(
      {url: `/api/file/${path}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: bodyPutFileApiFilePathPut,
        params
    },
      options);
    }
  


/**
 * @summary Get Details
 */
export const getDetails = (
    path: string,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<File>(
      {url: `/api/file/${path}/details`, method: 'get'
    },
      options);
    }
  

export const getGetDetailsKey = (path: string,) => [`/api/file/${path}/details`] as const;

    
export type GetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getDetails>>>
export type GetDetailsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Details
 */
export const useGetDetails = <TError = ErrorType<HTTPValidationError>>(
 path: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getDetails>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(path)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetDetailsKey(path) : null);
  const swrFn = () => getDetails(path, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary List Shares
 */
export const listShares = (
    
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<Share[]>(
      {url: `/api/share/`, method: 'get'
    },
      options);
    }
  

export const getListSharesKey = () => [`/api/share/`] as const;

    
export type ListSharesQueryResult = NonNullable<Awaited<ReturnType<typeof listShares>>>
export type ListSharesQueryError = ErrorType<unknown>

/**
 * @summary List Shares
 */
export const useListShares = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof listShares>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getListSharesKey() : null);
  const swrFn = () => listShares(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Create Share
 */
export const createShare = (
    shareData: BodyType<ShareData>,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<Share>(
      {url: `/api/share/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shareData
    },
      options);
    }
  


/**
 * @summary Get Share
 */
export const getShare = (
    id: string,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<ShareInfo>(
      {url: `/api/share/${id}`, method: 'get'
    },
      options);
    }
  

export const getGetShareKey = (id: string,) => [`/api/share/${id}`] as const;

    
export type GetShareQueryResult = NonNullable<Awaited<ReturnType<typeof getShare>>>
export type GetShareQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Share
 */
export const useGetShare = <TError = ErrorType<HTTPValidationError>>(
 id: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getShare>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof fetchWithAuth> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetShareKey(id) : null);
  const swrFn = () => getShare(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @summary Update Share
 */
export const updateShare = (
    id: string,
    shareDataPartial: BodyType<ShareDataPartial>,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<Share>(
      {url: `/api/share/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: shareDataPartial
    },
      options);
    }
  


/**
 * @summary Delete Share
 */
export const deleteShare = (
    id: string,
 options?: SecondParameter<typeof fetchWithAuth>) => {
      return fetchWithAuth<unknown>(
      {url: `/api/share/${id}`, method: 'delete'
    },
      options);
    }
  


