import React from "react";
import { useFiles } from "../files/filesStore";
import { isEqual } from "lodash";

export function ThumbnailImage(props: { file: string; }) {
    const { url, alt } = useFiles(state => {
        const file = state.files[props.file];
        const blobs = file.blobs;
        const hash = blobs.thumbnail ?? blobs.preview ?? blobs.main;
        const url = state.blobs[hash].url;
        const alt = file.name;
        return { url, alt };
    }, isEqual);
    return <img
        loading="lazy" // must be before src, see https://bugzilla.mozilla.org/show_bug.cgi?id=1647077
        src={url}
        title={props.file}
        alt={alt}
    />;
}
