export const debouncedSetter = <T>(minTime: number, setter: (value: T) => void) => {
    let lastSet = -1e+99;
    let nextValue: T | undefined;
    let timeout: NodeJS.Timeout | undefined;

    function setInternal() {
        setter(nextValue!);
        lastSet = performance.now();
        clearTimeout(timeout);
        timeout = undefined;
        nextValue = undefined;
    }
    function set(value: T) {
        nextValue = value;
        const dt = performance.now() - lastSet;
        if (dt < minTime) {
            if (timeout === undefined) {
                timeout = setTimeout(setInternal, minTime - dt);
            }
        } else {
            setInternal();
        }
    }
    function cancel() {
        clearTimeout(timeout);
    }
    return { set, cancel };
};
