import React from "react";
import { Button, FloatingLabel, FormLabel } from "react-bootstrap";
import "./Login.scss"
import { Field, FormikProvider, useFormik } from "formik";
import axios from "axios";
import { useLocation } from "wouter";
import { useAuth } from "./authStore";

export default function LoginPage() {

    const [location, setLocation] = useLocation();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            stayLoggedIn: false,
        },
        async onSubmit(values, formikHelpers) {
            const result = await axios.postForm("/api/auth/token", values, { validateStatus: null });
            if (result.status === 401) {
                alert("Invalid username or password");
                return;
            }
            const auth = useAuth.getState();
            auth.logIn(result.data.access_token);
            if (values.stayLoggedIn) {
                await auth.createSession();
            }

            const search = new URLSearchParams(window.location.search);
            if (search.has("return_url"))
                setLocation(search.get("return_url")!, { replace: true });
            else
                setLocation("/", { replace: true });
        },
    });

    return <div className="login-page">
        <h1>Fotoding</h1>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <FloatingLabel label="Username">
                    <Field name="username" type="text" className="form-control" placeholder="user" />
                </FloatingLabel>
                <FloatingLabel label="Password">
                    <Field name="password" type="password" className="form-control" placeholder="password" />
                </FloatingLabel>
                <div className="float-right">
                    <FormLabel>
                        <Field name="stayLoggedIn" type="checkbox" /> Stay logged in
                    </FormLabel>
                </div>
                <Button type="submit">Log in</Button>
            </FormikProvider>
        </form>
    </div>
}

// TODO: get authentication into orval
