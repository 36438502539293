import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

export function IconButton(props: { icon: IconProp, variant?: string, text: string, onClick?: () => void }) {
    const { icon, text, ...rest } = props;
    return <Button
        className="icon-button"
        title={text}
        {...rest}
    >
        <FontAwesomeIcon icon={icon} />
        <span>
            &ensp;
            {text}
        </span>
    </Button>
}
