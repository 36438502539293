import { faCopy, faEdit, faList, faPen, faPenAlt, faPlus, faShare, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button, ButtonGroup, Card, FloatingLabel, Form, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Tooltip } from "react-bootstrap";
import { Link } from "wouter";
import "./ShareUi.scss"
import { Share, ShareData, createShare, deleteShare, getGetShareKey, getListSharesKey, listShares, updateShare, useGetFiles, useListShares } from "../api/api";
import { mutate } from "swr";
import { Field, Formik, FormikProvider, useFormik } from "formik";
import { useFiles } from "../files/filesStore";
import { TagsFormikControl } from "../utils/TagsControl";

export default function ShareUi() {
    const listSharesResponse = useListShares();

    const [modalOpen, setModalOpen] = useState(false);

    if (listSharesResponse.isLoading)
        return <>Loading...</>
    if (listSharesResponse.error)
        return <>Error: {listSharesResponse.error}</>

    const shares = listSharesResponse.data!

    // return <pre>{JSON.stringify(listSharesResponse, undefined, 2)}</pre>;

    return <div className="share-ui">
        {shares.map(share => <ShareCard key={share._id} share={share} />)}
        <div className="share-ui-buttons">
            <Button
                onClick={() => setModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} />{" "}
                Create link...
            </Button>
            {/* <Button variant="outline-secondary">
                <FontAwesomeIcon icon={faList} />{" "}
                Links verwalten...
            </Button> */}
        </div>
        <ShareEditDialog show={modalOpen} onHide={() => setModalOpen(false)} />
    </div >
}

function ShareCard({ share }: { share: Share }) {
    const [modalOpen, setModalOpen] = useState(false);
    const link = new URL(`/share/${share._id}`, window.location.origin).toString();

    return <Card>
        <Card.Header>
            <Link to={link}>{share.name}</Link>
            <ButtonGroup>
                <Button variant="primary" title="Copy link"
                    onClick={() => navigator.clipboard.writeText(link)}>
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
                <Button variant="outline-primary" title="Edit"
                    onClick={() => setModalOpen(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button variant="outline-danger" title="Delete"
                    onClick={async () => {
                        await deleteShare(share._id!)
                        mutate(getListSharesKey());
                        mutate(getGetShareKey(share._id!));
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </ButtonGroup>
        </Card.Header>
        <Card.Body>
            <div className="share-description">
                {share.description}
            </div>
            Contains:
            <ul>
                {share.tags.length === 0 && share.events.length === 0 && <li>Nothing.</li>}
                {share.tags.length > 0 &&
                    <li>All files with one of these tags:
                        {share.tags.map(tag => <Badge key={tag}>{tag}</Badge>)}
                    </li>
                }
                {share.events.length > 0 &&
                    <li>All files from these events:
                        {share.events.map(event =>
                            <code key={event}>{event}</code>
                        )}
                    </li>
                }
                {share.exclude_tags.length > 0 &&
                    <li>Except if they have one of these tags:
                        {share.exclude_tags.map(tag =>
                            <Badge key={tag} bg="danger">{tag}</Badge>)}
                    </li>
                }
            </ul>
            {/* Total: {share.num_files} files */}
        </Card.Body>
        <ShareEditDialog share={share} show={modalOpen} onHide={() => setModalOpen(false)} />
    </Card>
}

const defaultShareData: ShareData = {
    name: "",
    description: "",
}

function ShareEditDialog(props: { share?: Share, show: boolean, onHide: () => void }) {
    const { share } = props;

    const formik = useFormik<ShareData>({
        initialValues: share ?? defaultShareData,
        onSubmit(values, formikHelpers) {
            if (share) {
                updateShare(share._id!, values);
                mutate(getGetShareKey(share._id!))
            }
            else
                createShare(values);
            mutate(getListSharesKey());
            props.onHide();
        }
    });

    const _files_data = useFiles(s => s.files);
    const _files = Object.values(_files_data ?? {});
    const tags = [...new Set(_files.flatMap(x => x.tags))].sort();
    const events = [...new Set(_files.map(x => x.event!).filter(x => x !== null))].sort();

    return <Modal className="share-edit" show={props.show} onHide={props.onHide}>
        <ModalHeader closeButton>
            <ModalTitle>{share ? "Edit" : "Create"} share link</ModalTitle>
        </ModalHeader>
        <ModalBody>
            <FormikProvider value={formik}>
                <FloatingLabel label="Name">
                    <Field className="form-control" type="text" name="name" placeholder="My Share Link" />
                </FloatingLabel>
                <FloatingLabel label="Description">
                    <Field as="textarea" className="form-control" type="text" name="description" placeholder="A description for my link"
                        style={{ height: "5em" }}
                    />
                </FloatingLabel>
                <div>
                    Included tags:
                    <TagsFormikControl name="tags" className="badge bg-primary" tagList={tags} />
                </div>
                <div>
                    Included events:
                    <TagsFormikControl name="events" className="event" tagList={events} />
                </div>
                <div>
                    Excluded tags:
                    <TagsFormikControl name="exclude_tags" className="badge bg-danger" tagList={tags} />
                </div>
            </FormikProvider>
        </ModalBody>
        <ModalFooter>
            <Button variant="outline-secondary" onClick={() => props.onHide()}>Cancel</Button>
            <Button onClick={() => formik.submitForm()}>OK</Button>
        </ModalFooter>
    </Modal >
}

