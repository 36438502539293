import React from "react";
import { IconButton } from "../utils/IconButton";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { processAndUpload } from "./processAndUpload";

export function UploadButton() {
    return <IconButton icon={faCloudUpload} text="Upload..." onClick={async () => {
        const el = document.createElement("input");
        el.type = "file";
        el.multiple = true;
        el.click();
        await new Promise((resolve) => el.onchange = resolve);
        const files = [...el.files ?? []];
        await Promise.all(files.map(file => processAndUpload({
            blob: file,
            lastModified: file.lastModified,
            name: file.name,
            path: file.path,
        })));
    }} />;
}
