import { create } from "zustand";

export interface UploadProgressState {
    items: Record<string, UploadProgressStatus>
    addItem(item: string, fileSize: number): void
    setProgress(item: string, status: string, progress?: number): void
    finish(item: string): void;
}

export interface UploadProgressStatus {
    fileSize: number
    phase: string
    phaseProgress?: number
}

export const useUploadProgress = create<UploadProgressState>()((set, get) => ({
    items: {},
    addItem(item, fileSize) {
        set({ items: { ...get().items, [item]: { fileSize, phase: "In Queue..." } } })
    },
    setProgress(item, status, progress) {
        set({ items: { ...get().items, [item]: { ...get().items[item], phase: status, phaseProgress: progress } } })
    },
    finish(item) {
        const items = { ...get().items };
        delete items[item];
        set({ items });
    }
}));
