export async function extractStillImage(videoBlob: Blob) {
    const videoUrl = URL.createObjectURL(videoBlob);
    const video = document.createElement("video");
    try {
        video.src = videoUrl;
        // document.body.prepend(video);
        await new Promise<void>(resolve => video.onloadedmetadata = () => resolve());

        video.currentTime = video.duration / 2;
        const width = video.width = video.videoWidth;
        const height = video.height = video.videoHeight;

        await new Promise<void>(resolve => video.onseeked = () => resolve());

        const osc = new OffscreenCanvas(width, height);
        const ctx = osc.getContext("2d");
        if (!ctx) throw new Error("Could not create rendering context");
        ctx.drawImage(video, 0, 0, width, height);

        const blob = await osc.convertToBlob({ quality: 1.0, type: "image/png" });
        const duration = video.duration;

        return { blob, meta: { duration } };
    } finally {
        video.remove();
        URL.revokeObjectURL(videoUrl);
    }

}