import { create } from "zustand";


export interface FilterStore {
    isActive: boolean;
    event: string | null | undefined;
    tags: string[];
    setActive: (active: boolean) => void;
    setEvent: (event: string | null | undefined) => void;
    setTags: (tags: string[]) => void;
}
export const useFilter = create<FilterStore>()((set, get) => ({
    isActive: false,
    event: undefined,
    tags: [],
    setActive(active) {
        set({ isActive: active });
    },
    setEvent(event) {
        set({ event });
    },
    setTags(tags) {
        set({ tags });
    },
}));
