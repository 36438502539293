import React from "react";
import { useLocation, useRoute } from "wouter";
import ShareUi from "./ShareUi";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";

export function ShareUiModal() {
    const [, setLocation] = useLocation();
    const [show] = useRoute("/share");
    return <Modal
        show={show}
        onHide={() => setLocation("/")}>
        <ModalHeader>
            <ModalTitle>
                Sharing Links
            </ModalTitle>
        </ModalHeader>
        <ModalBody>
            <ShareUi />
        </ModalBody>
    </Modal>;
}
