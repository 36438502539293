export async function downscale(image: ImageBitmap, targetSize: number, quality: number) {
    targetSize = Math.min(targetSize, Math.max(image.width, image.height)); // Don't upscale

    const scaleFactor = Math.max(image.width / targetSize, image.height / targetSize);
    const width = Math.round(image.width / scaleFactor);
    const height = Math.round(image.height / scaleFactor);

    const osc = new OffscreenCanvas(width, height);

    const context = osc.getContext("2d") as OffscreenCanvasRenderingContext2D;
    context.drawImage(image, 0, 0, width, height);

    return {
        blob: await osc.convertToBlob({ "type": "image/webp", quality }),
        width, height
    };
}
