import React, { useState } from "react";
import { useGroups } from "../files/useGroups";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { IconButton } from "../utils/IconButton";
import { faList } from "@fortawesome/free-solid-svg-icons";

export function Navigation() {
    const [showModal, setShowModal] = useState(false);

    return <div className="navigation">
        <div className="mobile">
            <IconButton
                icon={faList}
                text="Go to"
                onClick={() => setShowModal(true)}
            />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <ModalHeader>
                    <ModalTitle>Navigate to</ModalTitle>
                </ModalHeader>
                <ModalBody onClick={() => setShowModal(false)}>
                    {showModal && <NavList />}
                </ModalBody>
            </Modal>
        </div>
        <div className="desktop">
            <NavList />
        </div>
    </div>
}

function NavList() {
    const groups = useGroups();
    return <ul>
        {groups.map(x => <li key={x.uid}>
            <a href={`#${x.uid}`}>
                {x.title}
            </a>
        </li>)}
    </ul>
}
