import _ from "lodash";
import { modifyFiles, useFiles } from "../files/filesStore";
import { groupBy, sortBy } from "../utils/iterable-utils";
import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faICursor } from "@fortawesome/free-solid-svg-icons";
import { useSelection } from "./selectionStore";
import { IconButton } from "../utils/IconButton";

async function changeEvent() {
    const selection = useSelection.getState().selection;
    const { files } = useFiles.getState();

    const groupedEvents = groupBy(selection, file => files[file].event).filter(x => x.key !== null);
    const mostCommonEvent = _.maxBy(groupedEvents, group => group.items.length)?.key;

    let event = prompt("Which title should the event have?", mostCommonEvent ?? "");
    if (event === null) return;
    event = event?.trim() || null;

    await modifyFiles(selection.map(file => ({ name: file, event })));
}

export function EventSelection() {
    const { selection, setSelection } = useSelection();
    const numSelectedFiles = selection.length;

    const eventCounts = useFiles(state => {
        const selectedFiles = selection.map(f => state.files[f]);
        const eventCounts = new Map<string | null, number>();
        for (const file of selectedFiles) {
            if (!file) continue;
            const event = file.event ?? null;
            eventCounts.set(event, (eventCounts.get(event) ?? 0) + 1);
        }
        return eventCounts;
    }, _.isEqual);

    const changeEventButton = <IconButton variant="outline-primary" onClick={() => changeEvent()} icon={faICursor} text="Change event" />;

    if (eventCounts.size === 1) {
        const [event] = eventCounts.keys();
        return <h3>{event ?? "(no event)"}&nbsp;{changeEventButton}</h3>;
    }

    const eventCountsSorted = sortBy(eventCounts.entries(), ([k, v]) => -v);

    return <h3>
        {eventCountsSorted.map(([event, count]) => <div key={event}>
            <span onClick={e => {
                const state = useFiles.getState();
                const selectedFiles = selection.map(x => state.files[x]);
                const newSelection = e.ctrlKey ?
                    selectedFiles.filter(x => (x.event ?? null) !== event).map(x => x.name) :
                    selectedFiles.filter(x => (x.event ?? null) === event).map(x => x.name);
                setSelection(newSelection);
            }}>
                {event ?? <span className="text-muted">(no event)</span>}
                {count !== numSelectedFiles && <span className="text-muted"> ({count}/{numSelectedFiles})</span>}
            </span>
        </div>)}
        {changeEventButton}
    </h3>;
}
