import _ from "lodash";
import { modifyFiles, useFiles } from "../files/filesStore";
import { useSelection } from "./selectionStore";
import React from "react";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faX } from "@fortawesome/free-solid-svg-icons";


async function addTag() {
    const selection = useSelection.getState().selection;
    const { files } = useFiles.getState();
    const newTagsStr = prompt("Which tag do you want to add? You can add multiple by separating with `,`.");
    if (!newTagsStr) return;

    const newTags = newTagsStr.split(",").map(x => x.trim());

    await modifyFiles(selection.map(file => ({
        name: file,
        tags: [...new Set([...(files[file].tags ?? []), ...newTags])]
    })));
}

async function removeTag(tag: string) {
    const selection = useSelection.getState().selection;
    const { files } = useFiles.getState();

    await modifyFiles(selection.map(file => ({
        name: file,
        tags: files[file].tags?.filter(t => t !== tag)
    })));
}
export function TagSelection() {
    const selection = useSelection(state => state.selection);
    const numSelectedFiles = selection.length;
    const tagCounts = useFiles(store => {
        const selectedFiles = selection.map(f => store.files[f]);
        const tagCounts = new Map<string, number>();
        for (const file of selectedFiles) {
            if (!file.tags) continue;
            for (const tag of file.tags) {
                tagCounts.set(tag, (tagCounts.get(tag) ?? 0) + 1);
            }
        }
        return tagCounts;
    }, _.isEqual);

    return <div>
        Tags:&nbsp;
        {[...tagCounts.entries()].map(([tag, count]) => <Badge key={tag} bg="secondary">
            <span onClick={e => {
                const state = useFiles.getState();
                const selection = useSelection.getState();
                const selectedFiles = selection.selection.map(x => state.files[x]);
                const newSelection = e.ctrlKey ?
                    selectedFiles.filter(x => !x.tags?.includes(tag)).map(x => x.name) :
                    selectedFiles.filter(x => !!x.tags?.includes(tag)).map(x => x.name);
                selection.setSelection(newSelection);
            }}>
                {tag}
                {count !== numSelectedFiles && <> | {count}/{numSelectedFiles}</>}
            </span>
            &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faX} onClick={() => removeTag(tag)} />
        </Badge>)}

        <Badge bg="primary" onClick={() => addTag()}>
            <FontAwesomeIcon icon={faHashtag} />&nbsp;
            Add tag
        </Badge>
    </div>;
}
