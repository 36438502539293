import React from "react";

function _SizeDisplay(props: { bytes: number; }) {
    const { bytes } = props;
    let value = bytes;
    let unit = "bytes";
    if (bytes > 2 ** 30) {
        value = bytes / 2 ** 30;
        unit = "GiB";
    }
    else if (bytes > 2 ** 20) {
        value = bytes / 2 ** 20;
        unit = "MiB";
    }
    else if (bytes > 2 ** 10) {
        value = bytes / 2 ** 10;
        unit = "kiB";
    }
    if (value > 100) value = Math.round(value);
    else if (value > 10) value = Math.round(value * 10) / 10;
    else value = Math.round(value * 100) / 100;

    return <span title={`${bytes.toLocaleString(undefined, { useGrouping: true })} bytes`}>{value} {unit}</span>;
}
export const SizeDisplay = React.memo(_SizeDisplay);
