import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useField } from "formik";

export function TagsFormikControl(props: { name: string; className: string; tagList: string[]; }) {
    const { name, className, tagList } = props;
    const [fieldProps, meta, helper] = useField<string[]>(name);
    const tags = meta.value ?? [];

    return <TagsControl className={className} chosenTags={tags} setChosenTags={helper.setValue} tagChoices={tagList} />;
}

export function TagsControl(props: {
    className: string;
    tagChoices: string[];
    chosenTags: string[];
    setChosenTags: (value: string[]) => void
}) {
    const { className, tagChoices, chosenTags, setChosenTags } = props;
    const [showDropdown, setShowDropdown] = useState(false);

    const chosenTagsSet = new Set(chosenTags);
    const renderTooltip = (
        <Popover className={className}>
            {tagChoices
                .filter(x => !chosenTagsSet.has(x))
                .map(tag => <div
                    key={tag}
                    className={className}
                    onClick={() => {
                        setChosenTags(chosenTags.concat([tag]));
                        setShowDropdown(false);
                    }}>
                    {tag}
                </div>)}
        </Popover>
    );

    return <>
        {chosenTags.map(t => <span key={t} className={className}>
            {t}
            <FontAwesomeIcon icon={faXmark}
                onClick={() => setChosenTags(chosenTags.filter(x => x !== t))} />
        </span>)}
        <OverlayTrigger
            placement="bottom-start"
            overlay={renderTooltip}
            trigger="click"
            rootClose
            show={showDropdown}
            onToggle={() => setShowDropdown(false)}
        >
            <span className={className} onClick={() => setShowDropdown(true)}>
                <FontAwesomeIcon icon={faPlus} />
            </span>
        </OverlayTrigger>
    </>;
}
