import React, { useMemo, useState } from "react";
import "./UploadProgressArea.scss"
import { UploadProgressStatus, useUploadProgress } from "./uploadProgressStore";
import { UploadButton } from "./UploadButton";
import classNames from "classnames";
import { debouncedSetter } from "../utils/debouncedSetter";
import { SizeDisplay } from "./SizeDisplay";

export function UploadProgressArea() {
    const _uploadsRaw = useUploadProgress(s => s.items);
    const [uploads, setUploads] = useState<Record<string, UploadProgressStatus> | null>(null);

    const debouncer = useMemo(() => debouncedSetter(200, setUploads), [setUploads]);
    React.useEffect(() => debouncer.set(_uploadsRaw), [_uploadsRaw]);

    return <_UploadProgressArea uploads={uploads ?? {}} />
}
const _UploadProgressArea = React.memo(function _UploadProgressArea({ uploads }: { uploads: Record<string, UploadProgressStatus> }) {
    return <div className={classNames("upload-progress-area", { visible: Object.keys(uploads).length > 0 })}>
        <h3>
            Uploads ({Object.keys(uploads).length}, <SizeDisplay bytes={Object.values(uploads).map(x => x.fileSize ?? 0).reduce((a, b) => a + b, 0)} />)
        </h3>

        <div className="progress-item-list">
            {Object.entries(uploads).map(([filename, { phase, phaseProgress, fileSize }]) =>
                <div className="progress-item" key={filename}>
                    <div className="progress-item-name">
                        {filename} (<SizeDisplay bytes={fileSize} />)
                    </div>
                    <div className="progress-item-phase">
                        {phase}
                        {phaseProgress && `(${Math.round(phaseProgress * 1000) / 10} %)`}
                    </div>
                </div>
            )}
        </div>
    </div>;
});

