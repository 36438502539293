import React, { ReactNode } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from 'react-dnd-html5-backend';
import classNames from "classnames";
import "./DropZone.scss"
import { processAndUpload } from "../upload/processAndUpload";

export function DropZone(props: { children: ReactNode; }) {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: [NativeTypes.FILE],
        async drop(item: { files: File[]; items: DataTransferItemList }, monitor) {
            for (const _item of item.items) {
                const fsEntry = _item.webkitGetAsEntry();
                if (!fsEntry) continue;
                traverse(fsEntry);

                function traverse(fsEntry: FileSystemEntry, event?: string) {
                    if (fsEntry.isDirectory) {
                        const reader = (fsEntry as FileSystemDirectoryEntry).createReader();
                        reader.readEntries(entries => entries.forEach(x => traverse(x, fsEntry.name)));
                    }
                    else if (fsEntry.isFile) {
                        (fsEntry as FileSystemFileEntry).file(file => processAndUpload({
                            blob: file,
                            name: file.name,
                            lastModified: file.lastModified,
                            event,
                            path: electronMain && file.path
                        }))
                    }
                }
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver()
        }),
    }));

    return <div ref={drop} className={classNames("dropzone", { isOver })}>
        {props.children}
    </div>;
}
