import { getSelection } from "./selectionStore";
import React from "react";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { useFiles } from "../files/filesStore";
import { IconButton } from "../utils/IconButton";

function downloadSelection(blobType: "main" | "preview" | "thumbnail" = "main") {
    const blobs = useFiles.getState().blobs;
    for (const file of getSelection()) {
        const a = document.createElement("a");
        const blob = file.blobs[blobType];
        if (!blob) continue;
        a.href = blobs[blob].url;
        a.download = file.name;
        a.click();
    }
}
export function DownloadSelectionButton() {
    return <IconButton
        variant="outline-secondary"
        onClick={() => downloadSelection()}
        icon={faCloudDownload}
        text="Download"
    />;
}
