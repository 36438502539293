import React, { useState } from "react";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../utils/IconButton";
import { getFiles } from "../api/api";
import { useFiles } from "../files/filesStore";

export function RefreshButton() {
    const [isLoading, setLoading] = useState(false);

    return <IconButton icon={faRefresh} text={isLoading ? "Refresh..." : "Refresh"} variant="outline-secondary" onClick={async () => {
        setLoading(true);
        const data = await getFiles();
        useFiles.getState().hydrateFiles(Object.values(data));
        setLoading(false);
    }} />;
}
