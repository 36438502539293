import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSession } from "../api/api";
import uaparse from "ua-parser-js"
import * as jose from "jose";

export interface AuthState {
    sessionToken?: string,
    token?: string,
    user?: string,
    logIn: (token: string) => void,
    createSession: () => Promise<void>,
    logOut: () => void,
}

export const useAuth = create<AuthState>()(
    persist(
        (set) => ({
            logIn: (token: string) => {
                set({ token, user: jose.decodeJwt(token).sub })
            },
            createSession: async () => {
                const ua = uaparse();
                const isPWA = !window.matchMedia('(display-mode: browser)').matches;
                const descriptionParts = [ua.browser.name, "on", ua.os.name, ua.os.version, isPWA && "(PWA)"];
                const description = descriptionParts.filter(x => x).join(" ");
                set({ sessionToken: await createSession(description) })
            },
            logOut: () => set({ token: undefined, sessionToken: undefined }),
        }),
        { name: "auth" }
    )
);
