import React from "react";
import { useSelection } from "./selectionStore";
import { SelectionTools } from "./SelectionTools";
import "./Toolbar.scss"
import { Link } from "wouter";
import { ShareUiModal } from "../share/ShareUiModal";
import { faDesktop, faShareNodes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../utils/IconButton";
import { UploadButton } from "../upload/UploadButton";
import { FilterUi } from "../filter/FilterUi";
import { RefreshButton } from "./RefreshButton";
import { LogoutButton } from "../auth/LogoutButton";
import { UsersModal } from "../users/UsersScreen";
import { useAuth } from "../auth/authStore";
import { SessionsModal } from "../auth/SessionsModal";
import { Navigation } from "./Navigation";

export function Toolbar() {
    const hasSelection = useSelection(state => state.selection.length > 0);
    const isAdmin = useAuth(s => s.user === "admin")

    return <div className="toolbar">
        <div className="toolbar-inner">
            <LogoutButton />

            <RefreshButton />

            <Link to="/share" style={{ display: "contents" }}>
                <IconButton icon={faShareNodes} text="Manage share links..." variant="outline-secondary" />
            </Link>

            {isAdmin && <Link to="/users" style={{ display: "contents" }}>
                <IconButton icon={faUsers} text="Manage users..." variant="outline-secondary" />
            </Link>}

            {!isAdmin && <Link to="/sessions" style={{ display: "contents" }}>
                <IconButton icon={faDesktop} text="Manage sessions..." variant="outline-secondary" />
            </Link>}

            <UploadButton />

            <FilterUi />

            <ShareUiModal />

            {isAdmin && <UsersModal />}

            {!isAdmin && <SessionsModal />}

            {hasSelection && <SelectionTools />}

            <Navigation />
        </div>
    </div>;
}


