import { downscale } from "./downscale";

interface TransferredToWorker {
    blob: Blob
    port: MessagePort
}
interface TransferredFromWorker {
    width: number
    height: number
    preview: RescaledBuffer
    thumbnail: RescaledBuffer
}

interface RescaledBlob {
    blob: Blob
    width: number
    height: number
}
interface RescaledBuffer {
    arrayBuffer: ArrayBuffer
    width: number
    height: number
}

if (!globalThis.window) {
    onmessage = async e => {
        const { blob, port } = e.data as TransferredToWorker;

        const image = await createImageBitmap(blob);
        const preview = await downscale(image, 2000, 0.9);
        const thumbnail = await downscale(image, 128, 0.9);

        const previewBuffer = await preview.blob.arrayBuffer();
        const thumbnailBuffer = await thumbnail.blob.arrayBuffer();

        const message: TransferredFromWorker = {
            width: image.width,
            height: image.height,
            preview: {
                width: preview.width,
                height: preview.height,
                arrayBuffer: previewBuffer
            },
            thumbnail: {
                width: thumbnail.width,
                height: thumbnail.height,
                arrayBuffer: thumbnailBuffer
            }
        };
        image.close();

        port.postMessage(message, { transfer: [previewBuffer, thumbnailBuffer] });
    };

    // keep the worker open indefinitely
    setTimeout(() => { }, 1e99);
}

let worker: Worker | undefined;

interface RescaledBlob {
    blob: Blob
    width: number
    height: number
}

export function processInWorker(blob: Blob): Promise<{
    width: number, height: number,
    preview: RescaledBlob,
    thumbnail: RescaledBlob,
}> {
    return new Promise(resolve => {
        worker ??= new Worker(
            new URL("./imageProcessingWorker.tsx", import.meta.url),
            { name: "downscalerWorker", type: "module" });

        const { port1, port2 } = new MessageChannel();

        worker.postMessage({ blob, port: port2 } satisfies TransferredToWorker, [port2]);
        port1.onmessage = e => {
            const { preview, thumbnail, width, height } = e.data as TransferredFromWorker;
            resolve({
                width, height,
                preview: {
                    blob: new Blob([preview.arrayBuffer]),
                    width: preview.width,
                    height: preview.height,
                },
                thumbnail: {
                    blob: new Blob([thumbnail.arrayBuffer]),
                    width: thumbnail.width,
                    height: thumbnail.height,
                }
            });
        }
    });
}
