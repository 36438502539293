export function createAsyncQueue<TArg, TResult = void>(
    worker: (item: TArg) => Promise<TResult>,
    maxWorkers = 10
) {
    const queue: {
        item: TArg;
        resolve: (result: TResult) => void;
        reject: (reason: any) => void;
    }[] = [];
    let activeWorkers = 0;

    async function work() {
        if (activeWorkers >= maxWorkers) return;

        const item = queue.shift();
        if (!item) return;

        activeWorkers++;
        await worker(item.item)
            .then(item.resolve)
            .catch(item.reject);
        activeWorkers--;

        setTimeout(work);
    }

    function enqueue(item: TArg) {
        const result = new Promise<TResult>((resolve, reject) => {
            queue.push({ item, resolve, reject });
        });
        setTimeout(work);
        return result;
    }

    return {
        enqueue,
        get length() {
            return queue.length;
        },
        [Symbol.iterator]() {
            return queue[Symbol.iterator]();
        },
        toString() {
            return queue.toString();
        }
    };
}
