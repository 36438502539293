import { create } from "zustand";
import { useFiles } from "../files/filesStore";

export interface SelectionState {
    selection: string[],
    active?: string,
    setSelection: (selection: string[], active?: string) => void
}

export const useSelection = create<SelectionState>()((set, get) => ({
    selection: [],
    setSelection: (selection, active) => set({ selection, active }),
}))

export function getSelection() {
    const files = useFiles.getState().files;
    const selection = useSelection.getState().selection;
    return selection.map(x => files[x]);
}

export function toggleSelected(file: string) {
    const store = useSelection.getState();
    const isSelected = store.selection.includes(file);
    if (isSelected) {
        store.setSelection(store.selection.filter(x => x !== file));
    }
    else {
        store.setSelection([...store.selection, file], file);
    }
    return !isSelected;
}
