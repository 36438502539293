import React from "react";
import { useLocation, useRoute } from "wouter";
import { Button, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { createUser, deleteUser, getListUsersKey, useListUsers } from "../api/api";
import { Field, FormikProvider, useFormik } from "formik";
import { mutate } from "swr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export function UsersModal() {
    const [, setLocation] = useLocation();
    const [show] = useRoute("/users");
    return <Modal
        show={show}
        onHide={() => setLocation("/")}>
        <ModalHeader>
            <ModalTitle>
                Users
            </ModalTitle>
        </ModalHeader>
        <ModalBody>
            <UsersList />
        </ModalBody>
    </Modal>;
}

function UsersList() {
    const users = useListUsers();
    return <>
        <ul >
            {users.data?.map(x => <li >
                <div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
                    {x}
                    {x !== "admin" &&
                        <Button style={{ float: "right" }} variant="outline-danger"
                            onClick={async () => {
                                await deleteUser(x);
                                mutate(getListUsersKey());
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>}
                </div>
            </li>)}
        </ul>
        <hr />
        <NewUserUi />
    </>
}

function NewUserUi() {
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        async onSubmit(values) {
            await createUser(values);
            mutate(getListUsersKey())
        }
    })
    return <div>
        <h5>Create new user</h5>
        <form style={{ display: "flex", flexDirection: "column" }} onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <Field name="username" type="text" placeholder="username" /><br />
                <Field name="password" type="password" placeholder="password" />
                <Button type="submit">Create</Button>
            </FormikProvider>
        </form>
    </div>
}
