import React from "react";

export function Placeholder(props: { numItems: number; className: string; }) {
    return <div className={props.className} ref={el => {
        if (!el) return;
        if (el.childElementCount === props.numItems) return;

        while (el.childElementCount > props.numItems) {
            el.lastChild?.remove();
        }

        for (let i = el.childElementCount; i < props.numItems; i++) {
            el.append(document.createElement("div"));
        }
    }} />;
}
