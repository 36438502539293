import React, { memo, useMemo, useState } from "react";
import "./Files.scss";
import classNames from "classnames";
import { setPreview } from "../preview/Preview";
import { useSelection } from "../tools/selectionStore";
import { useFiles } from "./filesStore";
import { ThumbnailImage } from "../utils/ThumbnailImage";
import { Placeholder } from "../utils/Placeholder";
import { isEqual } from "lodash";
import { FileGroup, getGroups, useGroups } from "./useGroups";
import { useFilter } from "../filter/filterStore";

export function Files() {
    const groups = useGroups();

    const setSelection = useSelection(s => s.setSelection);

    return <div className="overview" onClick={(e) => (e.target as HTMLElement).matches(".thumbnail") || setSelection([])}>
        {groups.map(group => <GroupComponent group={group} key={group.uid} />)}
    </div>
}

const setActive = Symbol("activate");

const activateOnFocus = new IntersectionObserver((items) => {
    for (const item of items) {
        const isActive = item.target.hasAttribute("data-active");
        if (item.isIntersecting !== isActive) {
            item.target.toggleAttribute("data-active", item.isIntersecting);
            (item.target as any)[setActive]?.(item.isIntersecting);
        }
    }
}, { rootMargin: "200%" });

const navigationFinished = Symbol("navigation-finished");

const GroupComponent = memo(_GroupComponent, isEqual);
function _GroupComponent(props: { group: FileGroup }) {
    const { title, files, uid } = props.group;
    const [isActive, setIsActive] = useState(false);
    const hashGroupId = `#${uid}`;

    return <div
        className="group"
        id={uid}
        ref={el => {
            if (!el) return;

            activateOnFocus.observe(el);
            let lastTimeout: NodeJS.Timeout | undefined;
            (el as any)[setActive] = (newState: boolean) => {
                clearTimeout(lastTimeout);
                lastTimeout = setTimeout(() => setIsActive(newState), newState ? 100 : 1);
            }

            const canNavigate = !(navigationFinished in el);
            (el as any)[navigationFinished] = true;
            if (canNavigate && location.hash === hashGroupId) {
                location.hash = uid; // force navigation
            }
        }}
    >
        <h2><a href={hashGroupId}>{title}</a></h2>
        <div className="files">
            <Placeholder numItems={files.length} className="thumbnails-placeholders" />
            {isActive && <div className="thumbnails-actual">
                {files.map((file) => <FileComponent file={file.name} key={file.name} />)}
            </div>}
        </div>
    </div>
}

const FileComponent = memo(_FileComponent);
function _FileComponent(props: { file: string }) {
    const url = useFiles(state => {
        const blobs = state.files[props.file].blobs;
        const hash = blobs.thumbnail ?? blobs.preview ?? blobs.main
        return state.blobs[hash].url;
    });

    const presence = useFiles(state => {
        const blob = state.files[props.file].blobs.main;
        return state.blobs[blob].serverPresence;
    })

    const selected = useSelection(s => s.selection.includes(props.file));

    return <div
        className={classNames("thumbnail", { "thumbnail-waiting": presence !== "available", selected })}
        onPointerDown={e => {
            if (e.button !== 0) return;
            const { selection, active, setSelection } = useSelection.getState();
            const fileList = getGroups(Object.values((useFiles.getState()).files), useFilter.getState())
                .flatMap(x => x.files)
                .map(x => x.name);
            const startIndex = fileList.indexOf((e.shiftKey && active) || props.file);
            let endIndex = fileList.indexOf(props.file);
            let selectionStarted = e.ctrlKey || e.shiftKey;
            const startIsSelected = selection.includes(props.file);

            const el = e.currentTarget;
            el.setPointerCapture(e.pointerId);
            el.addEventListener("pointermove", onMove)
            el.addEventListener("pointerup", onUp)
            updateSelection();

            function updateSelection() {
                const min = Math.min(startIndex, endIndex);
                const max = Math.max(startIndex, endIndex);
                let newSelection = fileList.slice(min, max + 1);
                if (e.ctrlKey) {
                    if (startIsSelected)
                        newSelection = selection.filter(x => !newSelection.includes(x));
                    else
                        newSelection.push(...selection);
                }
                if (selectionStarted)
                    setSelection(newSelection, e.shiftKey ? active : fileList.at(startIndex));
            }
            function onMove(e: PointerEvent) {
                const hoveredFile = document.elementFromPoint(e.clientX, e.clientY)
                    ?.querySelector("img")?.title;
                if (!hoveredFile) return;
                if (startIndex !== endIndex)
                    selectionStarted = true;
                endIndex = fileList.indexOf(hoveredFile);
                updateSelection();
            }
            function onUp(e: PointerEvent) {
                el.removeEventListener("pointermove", onMove);
                el.removeEventListener("pointerup", onUp);
                if (!selectionStarted && !e.ctrlKey && !e.shiftKey)
                    setPreview(props.file);
            }
        }}
    >
        <ThumbnailImage file={props.file} />
        {typeof presence === "number"
            && <div className="progress" style={{ width: `${presence * 100}%` }} />}
    </div>;
}

