import React, { useEffect } from "react";
import { getSessionAccessToken } from "../api/api";
import { useAuth } from "./authStore";
import * as jose from "jose";

export function SessionRefresher() {
    const sessionToken = useAuth(x => x.sessionToken);
    useEffect(() => {
        if (!sessionToken) return;

        refreshToken();
        const interval = setInterval(refreshToken, 10_000);

        function hasFreshAccessToken() {
            const accessToken = useAuth.getState().token;
            if (!accessToken) return false;
            const payload = jose.decodeJwt(accessToken);
            const expiration = payload.exp! * 1000;
            const now = Date.now();
            return expiration - now > 30_000;
        }

        async function refreshToken() {
            if (hasFreshAccessToken()) return;

            const token = await getSessionAccessToken(sessionToken!);
            useAuth.getState().logIn(token);
        }
        return () => clearInterval(interval);
    }, [sessionToken]);
    return <></>;
}
