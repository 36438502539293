import { useFiles } from "./filesStore";
import { FilterStore, useFilter } from "../filter/filterStore";
import { memoize } from "lodash";
import { FileSlim } from "../api/api";

export interface FileGroup {
    title: string,
    uid: string
    files: FileSlim[]
}

function _getGroups(files: FileSlim[], filters: FilterStore) {
    if (filters.isActive) {
        files = files.filter((file) => filters.tags.every(f => file.tags?.includes(f))
            && (filters.event === undefined || filters.event === file.event));
    }

    const groups: FileGroup[] = []

    const takenUniqueKeys = new Map<string, number>();

    for (const file of files) {
        const lastGroup = groups.at(-1);

        const groupKey = file.event ?? file.meta.date?.substring(0, 7);

        if (!lastGroup || lastGroup.title !== groupKey) {
            // retrieve a unique key
            let uniqueKey = groupKey.toLowerCase().replaceAll(/[^a-z0-9]/g, "-");
            while (takenUniqueKeys.has(uniqueKey)) {
                const i = takenUniqueKeys.get(uniqueKey)!;
                takenUniqueKeys.set(uniqueKey, i + 1);
                uniqueKey += `-${i}`
            }
            takenUniqueKeys.set(uniqueKey, 1);

            groups.push({
                title: groupKey,
                uid: uniqueKey,
                files: [file]
            });
        }
        else {
            lastGroup.files.push(file);
        }
    }
    return groups;
}

export const getGroups = memoize(_getGroups);

export function useGroups() {
    const _files = useFiles(state => state.files);
    const _filters = useFilter();
    return getGroups(Object.values(_files), _filters);
}
