import React from "react";
import { DownloadSelectionButton } from "./DownloadSelectionButton";
import { EventSelection } from "./EventSelection";
import { TagSelection } from "./TagSelection";
import { DeleteSelectionButton } from "./DeleteSelectionButton";
import { useSelection } from "./selectionStore";
import { ButtonGroup } from "react-bootstrap";
import { ChangeDateButton } from "./ChangeDateButton";

export function SelectionTools() {
    const selectedFiles = useSelection(s => s.selection);

    return <div className="selection-tools">
        <div className="selection-info">
            <small>
                {selectedFiles.length > 1 ? <>{selectedFiles.length} files selected</> : <>One file selected</>}
            </small>

            <EventSelection />

            <TagSelection />
        </div>

        <ButtonGroup>
            <DownloadSelectionButton />
            <ChangeDateButton />
            <DeleteSelectionButton />
        </ButtonGroup>

    </div>
}

