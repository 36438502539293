import React from "react";
import { useLocation, useRoute } from "wouter";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./authStore";
import * as jose from "jose";
import { Button, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { getGetSessionsKey, revokeSession, useGetSessions } from "../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mutate } from "swr";

export function SessionsModal() {
    const [, setLocation] = useLocation();
    const [show] = useRoute("/sessions");
    return <Modal
        show={show}
        onHide={() => setLocation("/")}>
        <ModalHeader>
            <ModalTitle>
                Sessions
            </ModalTitle>
        </ModalHeader>
        <ModalBody>
            <SessionsList />
        </ModalBody>
    </Modal>;
}
function SessionsList() {
    const sessions = useGetSessions();
    const thisSessionId = useAuth(s => s.sessionToken !== undefined && jose.decodeJwt(s.sessionToken).session_id as string);
    return <>
        <ul>
            {sessions.data?.map(x => <li>
                <Button style={{ float: "right" }} variant="outline-danger"
                    onClick={async () => {
                        await revokeSession(x.id);
                        mutate(getGetSessionsKey());
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
                <div>
                    {x.description}
                    &ensp;{x.id === thisSessionId && <small className="text-muted">(this session)</small>}
                    <br />Last used: {new Date(x.last_used).toLocaleString()}
                </div>
            </li>)}
        </ul>
    </>;
}
